<template>
    <b-card title="Add Country">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Country Name" label-for="name">
                            <validation-provider name="Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                                        placeholder="Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Description -->
                    <!-- <b-col cols="12">
                        <b-form-group label="Description" label-for="Description">
                            <validation-provider name="Description" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="icon" v-model="description" :state="errors.length > 0 ? false : null"
                                        placeholder="Description" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->

                    <b-col cols="12">
                        <b-form-group label="Description" label-for="description">
                            <validation-provider name="description" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <ckeditor :editor="editor" :config="editorConfig" id="description" v-model="description"
                                        class="form-control form-control-lg"></ckeditor>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Coupon Code -->
                    <b-col cols="12">
                        <b-form-group label="Coupon Code" label-for="Coupon Code">
                            <validation-provider name="Coupon Code" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="coupon_code" v-model="coupon_code"
                                        :state="errors.length > 0 ? false : null" placeholder="Coupon Code" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Select discount Type" label-for="offer Payment Type">
                            <validation-provider name="offer Payment Type" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="discount_type" v-model="discount_type"
                                        :options="discountTypeOptions" :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <!-- <div class="mt-3">Selected: <strong>{{ discount_type }}</strong></div> -->
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Amount -->
                    <b-col cols="12" v-if="discount_type == 0">
                        <b-form-group label="Amount" label-for="Amount">
                            <validation-provider name="Amount" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="amount" type="number" v-model="amount"
                                        :state="errors.length > 0 ? false : null" placeholder="Amount" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Percent -->
                    <b-col cols="12" v-if="discount_type == 1">
                        <b-form-group label="Percent" label-for="Percent">
                            <validation-provider name="Percent" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="percent" type="number" v-model="percent"
                                        :state="errors.length > 0 ? false : null" placeholder="Percent" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    required
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            name: '',
            description: '',
            coupon_code: '',
            discount_type: null,
            percent: 0,
            amount: 0,
            discountTypeOptions: [
                { 'value': null, text: 'Please select discount Type' },
                { 'value': 0, 'text': 'amount' },
                { 'value': 1, 'text': 'percent' }
            ],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.

            },
        }
    },
    created() {
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        reset() {
            this.name = '',
            this.description = '',
            this.coupon_code = '',
            this.discount_type = ''
            this.percent = ''
            this.amount = ''
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
            
            if (this.discount_type == 1) {
                this.discount_type = 1;
                this.percent = this.percent;
                this.amount = 0;
            } else {
                this.discount_type = 0;
                this.amount =  this.amount;
                this.percent = 0;
            }
            // console.log('discount_type =>', this.discount_type)
            // console.log('amount =>', this.amount, 'percent =>', this.percent)

            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
            db.collection("coupon")
                .add({
                    name: this.name,
                    description: this.description,
                    coupon_code: this.coupon_code,
                    percent: this.percent,
                    amount: this.amount,
                    created: date,
                    modified: date,
                })
                .then((doc) => {
                    console.log("Added Successfully!");
                    store.dispatch('app/commitDeactivateLoader');
                    this.$router.push({ name: 'coupon' });
                })
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>